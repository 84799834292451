/* tslint:disable */
/* eslint-disable */
/**
 * AddressCheckPublic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AddressCheckFeedbackDto
 */
export interface AddressCheckFeedbackDto {
  /**
   *
   * @type {string}
   * @memberof AddressCheckFeedbackDto
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof AddressCheckFeedbackDto
   */
  feedback?: string;
}

/**
 * Check if a given object implements the AddressCheckFeedbackDto interface.
 */
export function instanceOfAddressCheckFeedbackDto(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function AddressCheckFeedbackDtoFromJSON(
  json: any
): AddressCheckFeedbackDto {
  return AddressCheckFeedbackDtoFromJSONTyped(json, false);
}

export function AddressCheckFeedbackDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddressCheckFeedbackDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    feedback: !exists(json, 'feedback') ? undefined : json['feedback'],
  };
}

export function AddressCheckFeedbackDtoToJSON(
  value?: AddressCheckFeedbackDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    feedback: value.feedback,
  };
}
