import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import * as React from 'react';
import { FC } from 'react';
import {
  Box,
  CircularProgress,
  LinearProgress,
  Typography,
} from '@mui/material';

interface LoadingIndicatorProps {
  loading: boolean;
  loadingIconContainerStyles?: SxProps<Theme>;
  infoText?: string;
  children?: React.ReactNode;
  type: 'circular' | 'linear';
}

export const LoadingIndicator: FC<LoadingIndicatorProps> = ({
  loading,
  loadingIconContainerStyles,
  children,
  infoText,
  type = 'circular',
}) =>
  loading ? (
    'circular' === type ? (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...loadingIconContainerStyles,
        }}
      >
        <CircularProgress />
      </Box>
    ) : (
      <Box
        sx={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: (theme) => theme.spacing(64),
          display: 'flex',
          flexDirection: 'column',
          gap: (theme) => theme.spacing(1),
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          margin: 'auto',
          ...loadingIconContainerStyles,
        }}
      >
        <>
          <Typography>{infoText ?? ''}</Typography>
          <LinearProgress
            sx={{ width: (theme) => theme.spacing(32), margin: 'auto' }}
          />
        </>
      </Box>
    )
  ) : (
    children
  );
