import React, { FC } from 'react';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from 'react-router-dom';
import {
  BaseThemeProvider as AdminThemeProvider,
  Header as AdminHeader,
  initI18n,
} from '@verona/ui-components';
import { CssBaseline } from '@mui/material';
import { initEffects } from '@ngneat/effects';
import { enableElfProdMode } from '@ngneat/elf';
import {
  appEnvironment,
  appVersion,
  LoadingIndicator,
  PublicThemeProvider,
} from '@verona/components';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PublicAuthGuard } from '@verona/address-check-public';
import { isAuthenticated$ } from '@verona/admin-authentication';
import { useObservable } from '@ngneat/react-rxjs';
import { NotificationOutlet } from '@verona/notifications';
import localesDe from '../assets/locales.de';

initEffects();
initI18n({ de: localesDe });
enableElfProdMode();

const AdminLayout: FC = () => {
  const [isAuthenticated] = useObservable(isAuthenticated$);
  const { t } = useTranslation('address-check');

  return !isAuthenticated ? (
    <LoadingIndicator
      loading={true}
      type={'linear'}
      infoText={t('loading.login') as string}
    />
  ) : (
    <AdminThemeProvider>
      <CssBaseline />
      <AdminHeader
        environment={appEnvironment()}
        tenant={'businessPartners'}
        appVersion={appVersion()}
      />
      <Outlet />
    </AdminThemeProvider>
  );
};

const PublicLayout: FC = () => (
  <PublicThemeProvider>
    <CssBaseline />
    <Outlet />
  </PublicThemeProvider>
);

export const App: FC = () => {
  const { t } = useTranslation('address-check');

  const router = createBrowserRouter([
    {
      path: '/admin',
      element: <AdminLayout />,
      children: [
        {
          index: true,
          element: <Navigate replace to="/admin/search" />,
        },
        {
          path: '/admin/form',
          children: [
            {
              index: true,
              lazy: async () => ({
                Component: (await import('@verona/address-check-admin-form'))
                  .AddressCheckFormPage,
              }),
            },
          ],
        },
        {
          path: '/admin/search',
          children: [
            {
              index: true,
              lazy: async () => ({
                Component: (await import('@verona/address-check-admin-search'))
                  .AddressCheckSearchPage,
              }),
            },
          ],
        },
        {
          path: '/admin/settings',
          children: [
            {
              index: true,
              lazy: async () => ({
                Component: (
                  await import('@verona/address-check-admin-settings')
                ).AddressCheckSettingsPage,
              }),
            },
          ],
        },
        {
          path: '/admin/quick-create',
          children: [
            {
              index: true,
              lazy: async () => ({
                Component: (
                  await import('@verona/address-check-admin-quick-create')
                ).AddressCheckQuickCreatePage,
              }),
            },
          ],
        },
      ],
    },
    {
      path: '/login',
      element: <PublicLayout />,
      children: [
        {
          index: true,
          lazy: async () => ({
            Component: (await import('@verona/address-check-public'))
              .AddressCheckPublicLoginPage,
          }),
        },
      ],
    },
    {
      path: '*',
      element: (
        <PublicAuthGuard>
          <PublicLayout />
        </PublicAuthGuard>
      ),
      children: [
        {
          index: true,
          lazy: async () => ({
            Component: (await import('@verona/address-check-public'))
              .AddressCheckPublicPage,
          }),
        },
      ],
    },
  ]);

  return (
    <>
      <NotificationOutlet />
      <RouterProvider
        router={router}
        fallbackElement={
          <LoadingIndicator
            loading={true}
            type={'linear'}
            infoText={t('loading.addressCheck') as string}
          />
        }
      />
    </>
  );
};
