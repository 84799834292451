/* tslint:disable */
/* eslint-disable */
/**
 * AddressCheckPublic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AddressCheckInstitutionTeacherDto
 */
export interface AddressCheckInstitutionTeacherDto {
  /**
   *
   * @type {number}
   * @memberof AddressCheckInstitutionTeacherDto
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AddressCheckInstitutionTeacherDto
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof AddressCheckInstitutionTeacherDto
   */
  email?: string | null;
  /**
   *
   * @type {Date}
   * @memberof AddressCheckInstitutionTeacherDto
   */
  dateOfBirth?: Date | null;
}

/**
 * Check if a given object implements the AddressCheckInstitutionTeacherDto interface.
 */
export function instanceOfAddressCheckInstitutionTeacherDto(
  value: object
): boolean {
  let isInstance = true;

  return isInstance;
}

export function AddressCheckInstitutionTeacherDtoFromJSON(
  json: any
): AddressCheckInstitutionTeacherDto {
  return AddressCheckInstitutionTeacherDtoFromJSONTyped(json, false);
}

export function AddressCheckInstitutionTeacherDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddressCheckInstitutionTeacherDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    fullName: !exists(json, 'fullName') ? undefined : json['fullName'],
    email: !exists(json, 'email') ? undefined : json['email'],
    dateOfBirth: !exists(json, 'dateOfBirth')
      ? undefined
      : json['dateOfBirth'] === null
      ? null
      : new Date(json['dateOfBirth']),
  };
}

export function AddressCheckInstitutionTeacherDtoToJSON(
  value?: AddressCheckInstitutionTeacherDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    fullName: value.fullName,
    email: value.email,
    dateOfBirth:
      value.dateOfBirth === undefined
        ? undefined
        : value.dateOfBirth === null
        ? null
        : value.dateOfBirth.toISOString().substring(0, 10),
  };
}
