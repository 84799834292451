import React, { FC, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  FormControlLabel,
  inputLabelClasses,
  Skeleton,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { AddressCheckStepProps } from './step-props';
import {
  hasLotteryErrors$,
  lottery$,
  lotteryLoading$,
  teachers$,
  teachersLoading$,
} from '../../store';
import { useTranslation } from 'react-i18next';
import { useObservable } from '@ngneat/react-rxjs';
import { dispatch } from '@ngneat/effects';
import sanitize from 'sanitize-html';
import { AddressCheckInstitutionTeacherDto } from '@verona/address-check-public-api';
import { RegisterTeacher } from '../register-teacher';
import { LoadingButton } from '@mui/lab';
import { Refresh } from '@mui/icons-material';
import {
  deleteLotteryData,
  loadLotteryData,
  loadTeacherStaff,
  persistLotteryData,
  reloadTeachers,
  updateLotteryData,
} from '../../store/address-check/address-check.actions';

export const LotteryPage: FC<AddressCheckStepProps> = ({
  config,
  configLoading,
  isMobile,
  isActive,
  addressCheckInstitutionId,
}) => {
  const { t } = useTranslation('address-check-public', {
    keyPrefix: 'steps.lottery',
  });
  const theme = useTheme();

  const [lottery] = useObservable(lottery$);
  const [lotteryLoading] = useObservable(lotteryLoading$);
  const [selectedTeacher, setSelectedTeacher] =
    useState<AddressCheckInstitutionTeacherDto>();
  const [teachers] = useObservable(teachers$);
  const [teachersLoading] = useObservable(teachersLoading$);
  const [hasLotteryErrors] = useObservable(hasLotteryErrors$);

  useEffect(() => {
    isActive &&
      dispatch(
        loadLotteryData({ addressCheckInstitutionId }),
        loadTeacherStaff({ addressCheckInstitutionId })
      );
  }, [addressCheckInstitutionId, isActive]);

  useEffect(() => {
    setSelectedTeacher({
      id: lottery?.lotteryParticipantId,
      fullName: lottery?.lotteryParticipantName,
    } as AddressCheckInstitutionTeacherDto);
  }, [lottery]);

  return lotteryLoading || configLoading ? (
    <Skeleton variant={'text'} sx={{ width: '40%' }} />
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        my: 5,
      }}
    >
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'baseline',
          justifyContent: 'space-between',
          [theme.breakpoints.down('md')]: {
            pb: 4,
          },
        }}
      >
        <Typography
          variant={'h3'}
          dangerouslySetInnerHTML={{
            __html: sanitize(config.schoolDataPageMotivatorTitle ?? ''),
          }}
        />
        <RegisterTeacher />
      </Box>
      <FormControlLabel
        value="top"
        control={
          <Switch
            data-testid={'lotterySwitch'}
            color="primary"
            onChange={(e, checked) => {
              dispatch(
                updateLotteryData({
                  selected: checked,
                  shouldDisplayError: !checked,
                }),
                !checked
                  ? deleteLotteryData({
                      addressCheckInstitutionId,
                    })
                  : {}
              );
            }}
            checked={lottery?.selected || false}
          />
        }
        label={config.schoolDataPageMotivatorLabel}
        labelPlacement="end"
      />
      <Box
        dangerouslySetInnerHTML={{
          __html: sanitize(config.schoolDataPageMotivatorNotice ?? ''),
        }}
      />
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <Autocomplete
          disablePortal
          value={selectedTeacher?.id != null ? selectedTeacher : null}
          options={teachers ?? []}
          getOptionLabel={(t) => t.fullName ?? ''}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          sx={{
            width: 300,
            [theme.breakpoints.down('md')]: {
              width: '100%',
              mt: 2,
            },
          }}
          onChange={(_, value) => {
            const updatedLottery = {
              lotteryParticipantId: value?.id,
              lotteryParticipantName: value?.fullName,
              lotteryParticipantEmail: value?.email ?? undefined,
            };
            dispatch(
              updateLotteryData({
                ...updatedLottery,
                shouldDisplayError: false,
              }),
              updatedLottery.lotteryParticipantId
                ? persistLotteryData({
                    addressCheckInstitutionId,
                    lotteryDto: updatedLottery,
                  })
                : {}
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{
                sx: {
                  [`&.${inputLabelClasses.shrink}`]: {
                    [`&.${inputLabelClasses.focused}`]: {
                      color: (theme) => theme.palette.text.primary,
                    },
                  },
                },
              }}
              label={t('teacher.label')}
              error={hasLotteryErrors && lottery?.shouldDisplayError}
              variant="outlined"
            />
          )}
          disabled={!(lottery?.selected ?? true)}
        />
        <LoadingButton
          disabled={!(lottery?.selected ?? true)}
          onClick={() => {
            dispatch(
              reloadTeachers({
                addressCheckInstitutionId,
              })
            );
          }}
          endIcon={<Refresh />}
          loading={teachersLoading}
          variant={'contained'}
        >
          {t('refresh.label')}
        </LoadingButton>
      </Box>
    </Box>
  );
};
