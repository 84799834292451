import { createAction, createEffect, ofType, props } from '@ngneat/effects';
import { tap } from 'rxjs';
import { AddressCheckInstitutionLoginApi } from '@verona/address-check-public-api';
import { apiConfiguration } from '../../lib';
import {
  setAddressCheckInstitutionIdValidationError,
  setIsValidAddressCheckInstitutionId,
} from './login.repository';

export const validateInstitutionId = createAction(
  '[Institution] Validate Institution Id',
  props<{ addressCheckInstitutionId: string | null }>()
);

export const validateInstitutionId$ = createEffect((actions) => {
  const onNotValid = (message: string) =>
    setAddressCheckInstitutionIdValidationError(message);

  return actions.pipe(
    ofType(validateInstitutionId),
    tap((it) => {
      if (it.addressCheckInstitutionId == null) {
        onNotValid('invalid');
        return;
      }
      new AddressCheckInstitutionLoginApi(apiConfiguration)
        .addressCheckInstitutionIdIsActive({
          addressCheckInstitutionId: it.addressCheckInstitutionId,
        })
        .then(() => {
          setIsValidAddressCheckInstitutionId(true);
        })
        .catch((error) => {
          error.response.text().then((errorResponseBody: string) => {
            onNotValid(errorResponseBody);
          });
        });
    })
  );
});
