import * as ReactDOM from 'react-dom/client';
import { App } from './app/app';
import { MsalAuthProvider } from '@verona/admin-authentication';
import { BaseThemeProvider } from '@verona/ui-components';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BaseThemeProvider>
    <MsalAuthProvider protectedRoutes={['/admin']}>
      <App />
    </MsalAuthProvider>
  </BaseThemeProvider>
);
