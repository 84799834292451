import { createStore, select, withProps } from '@ngneat/elf';

interface LoginsStoreProps {
  isValidAddressCheckInstitutionId: boolean | null;
  AddressCheckInstitutionIdValidationError: string | null;
  hasLoginError: boolean;
}

const loginsStore = createStore(
  { name: 'logins' },
  withProps<LoginsStoreProps>({
    hasLoginError: false,
  } as LoginsStoreProps)
);

export const isValidAddressCheckInstitutionId$ = loginsStore.pipe(
  select((state) => state.isValidAddressCheckInstitutionId)
);

export const addressCheckInstitutionIdValidationError$ = loginsStore.pipe(
  select((state) => state.AddressCheckInstitutionIdValidationError)
);

export const hasLoginError$ = loginsStore.pipe(
  select((state) => state.hasLoginError)
);

export function setIsValidAddressCheckInstitutionId(
  isValidAddressCheckInstitutionId: boolean
) {
  loginsStore.update((state) => ({
    ...state,
    isValidAddressCheckInstitutionId: isValidAddressCheckInstitutionId,
  }));
}

export function setAddressCheckInstitutionIdValidationError(
  addressCheckInstitutionIdValidationError: string | null
) {
  loginsStore.update((state) => ({
    ...state,
    isValidAddressCheckInstitutionId: false,
    AddressCheckInstitutionIdValidationError:
      addressCheckInstitutionIdValidationError,
  }));
}

export function setHasLoginError(loginError: boolean) {
  loginsStore.update((state) => ({
    ...state,
    hasLoginError: loginError,
  }));
}
