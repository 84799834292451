import { createStore, select, withProps } from '@ngneat/elf';
import {
  persistState,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';
import { tap } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { TokenBody } from '../../page';

interface AuthStoreProps {
  token?: string;
  loginProcessing?: boolean;
}

const authStore = createStore(
  { name: 'auth' },
  withProps<AuthStoreProps>({} as AuthStoreProps)
);

persistState(authStore, {
  key: 'auth',
  storage: sessionStorageStrategy,
  source: () =>
    authStore.pipe(
      select(({ token }) => ({
        token,
      }))
    ),
});

export const authToken$ = authStore.pipe(
  tap((state) => {
    if (!state.token) return;
    const decoded = jwt_decode<TokenBody>(state.token);

    if (Date.now() >= decoded.exp * 1000) clearAuth();
  }),
  select((state) => state.token ?? '')
);

export const isAuthenticated$ = authStore.pipe(
  select((state) => !!state.token)
);

export const loginProcessing$ = authStore.pipe(
  select((state) => state.loginProcessing)
);

export const updateAuth = (newState: Partial<AuthStoreProps>) =>
  authStore.update((state) => ({
    ...state,
    ...newState,
  }));

export const clearAuth = () => authStore.reset();
