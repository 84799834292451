/* tslint:disable */
/* eslint-disable */
/**
 * AddressCheckPublic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SchoolDataDto
 */
export interface SchoolDataDto {
  /**
   *
   * @type {string}
   * @memberof SchoolDataDto
   */
  name1: string;
  /**
   *
   * @type {string}
   * @memberof SchoolDataDto
   */
  name2?: string | null;
  /**
   *
   * @type {string}
   * @memberof SchoolDataDto
   */
  country: string;
  /**
   *
   * @type {string}
   * @memberof SchoolDataDto
   */
  postalCode: string;
  /**
   *
   * @type {string}
   * @memberof SchoolDataDto
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof SchoolDataDto
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof SchoolDataDto
   */
  streetNumber: string;
  /**
   *
   * @type {string}
   * @memberof SchoolDataDto
   */
  streetAddition?: string | null;
  /**
   *
   * @type {string}
   * @memberof SchoolDataDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof SchoolDataDto
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof SchoolDataDto
   */
  homepage?: string | null;
}

/**
 * Check if a given object implements the SchoolDataDto interface.
 */
export function instanceOfSchoolDataDto(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name1' in value;
  isInstance = isInstance && 'country' in value;
  isInstance = isInstance && 'postalCode' in value;
  isInstance = isInstance && 'city' in value;
  isInstance = isInstance && 'street' in value;
  isInstance = isInstance && 'streetNumber' in value;
  isInstance = isInstance && 'email' in value;
  isInstance = isInstance && 'phone' in value;

  return isInstance;
}

export function SchoolDataDtoFromJSON(json: any): SchoolDataDto {
  return SchoolDataDtoFromJSONTyped(json, false);
}

export function SchoolDataDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SchoolDataDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name1: json['name1'],
    name2: !exists(json, 'name2') ? undefined : json['name2'],
    country: json['country'],
    postalCode: json['postalCode'],
    city: json['city'],
    street: json['street'],
    streetNumber: json['streetNumber'],
    streetAddition: !exists(json, 'streetAddition')
      ? undefined
      : json['streetAddition'],
    email: json['email'],
    phone: json['phone'],
    homepage: !exists(json, 'homepage') ? undefined : json['homepage'],
  };
}

export function SchoolDataDtoToJSON(value?: SchoolDataDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name1: value.name1,
    name2: value.name2,
    country: value.country,
    postalCode: value.postalCode,
    city: value.city,
    street: value.street,
    streetNumber: value.streetNumber,
    streetAddition: value.streetAddition,
    email: value.email,
    phone: value.phone,
    homepage: value.homepage,
  };
}
