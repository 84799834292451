import { createEffect, ofType } from '@ngneat/effects';
import { tap, withLatestFrom } from 'rxjs';
import { debugAction } from '@verona/utils';
import { apiConfiguration } from '../../lib';
import {
  AddressCheckInstitutionApi,
  LotteryDto,
  UpdateTeacherStaffOfAddressCheckInstitutionRequest,
} from '@verona/address-check-public-api';
import {
  lottery$,
  LotteryExtension,
  removeSchoolDataValidationError,
  removeTeacherStaffValidationError,
  setCurrentStep,
  setFeedback,
  setSchoolData,
  setSchoolDataValidationErrors,
  setTeacherStaffValidationErrors,
  updateConfiguration,
  updateLoading,
  updateLottery,
  updateLotteryEditMode,
  updateOriginalTeachers,
  updateSchoolDataEditMode,
  updateTeachers,
  updateTeacherStaff,
  updateTeacherStaffEditMode,
} from './address-check.repository';
import { TeacherStaffValidationErrorKey } from './address-check.definitions';
import { addErrorNotification } from '@verona/notifications';
import { clearAuth } from '../auth';
import {
  completeAddressCheck,
  deleteLotteryData,
  initializeAddressCheck,
  loadLotteryData,
  loadSchoolData,
  loadTeacherStaff,
  persistLotteryData,
  persistTeacherStaffAction,
  reloadTeachers,
  removeSchoolDataValidationErrorAction,
  setSchoolDataEditMode,
  updateFeedback,
  updateLotteryData,
  updateSchoolData,
  updateStep,
  updateTeacherStaffAction,
} from './address-check.actions';

export const initializeAddressCheck$ = createEffect((actions) => {
  return actions.pipe(
    ofType(initializeAddressCheck),
    debugAction(),
    tap(() => updateLoading({ configuration: true })),
    tap((request) => {
      new AddressCheckInstitutionApi(apiConfiguration)
        .getAddressCheckConfiguration(request)
        .then((config) => {
          updateConfiguration(config);
          new AddressCheckInstitutionApi(apiConfiguration)
            .getStepOfAddressCheckInstitution(request)
            .then(setCurrentStep)
            .catch((_) => addErrorNotification('address-check-public:error'));
        })
        .catch((_) => addErrorNotification('address-check-public:error'))
        .finally(() => updateLoading({ configuration: false }));
    })
  );
});

export const loadSchoolData$ = createEffect((actions) => {
  return actions.pipe(
    ofType(loadSchoolData),
    debugAction(),
    tap(() => updateLoading({ schoolData: true })),
    tap((request) => {
      new AddressCheckInstitutionApi(apiConfiguration)
        .getSchoolData(request)
        .then(setSchoolData)
        .catch((error) => addErrorNotification('address-check-public:error'))
        .finally(() => updateLoading({ schoolData: false }));
    })
  );
});

export const removeSchoolDataValidationError$ = createEffect((actions) => {
  return actions.pipe(
    ofType(removeSchoolDataValidationErrorAction),
    debugAction(),
    tap((value) => {
      removeSchoolDataValidationError(value.fieldName);
    })
  );
});

export const setSchoolDataEditMode$ = createEffect((actions) => {
  return actions.pipe(
    ofType(setSchoolDataEditMode),
    debugAction(),
    tap(({ editMode }) => {
      updateSchoolDataEditMode(editMode);
    })
  );
});

export const updateSchoolData$ = createEffect((actions) => {
  return actions.pipe(
    ofType(updateSchoolData),
    debugAction(),
    tap(() => updateLoading({ editSchoolData: true })),
    tap((request) => {
      new AddressCheckInstitutionApi(apiConfiguration)
        .updateSchoolData(request)
        .then((schoolData) => {
          setSchoolData(schoolData);
          updateSchoolDataEditMode(false);
        })
        .catch((error) => {
          error.response.json().then(setSchoolDataValidationErrors);
        })
        .finally(() => updateLoading({ editSchoolData: false }));
    })
  );
});

export const reloadTeachers$ = createEffect((actions) => {
  return actions.pipe(
    ofType(reloadTeachers),
    debugAction(),
    tap(() => updateLoading({ teachers: true })),
    tap((request) => {
      new AddressCheckInstitutionApi(apiConfiguration)
        .getTeacherStaffOfAddressCheckInstitution(request)
        .then((result) => {
          updateTeachers(result.teachers ?? []);
          updateOriginalTeachers(result.teachers ?? []);
        })
        .catch((error) => addErrorNotification('address-check-public:error'))
        .finally(() => updateLoading({ teachers: false }));
    })
  );
});

export const loadTeacherStaff$ = createEffect((actions) => {
  return actions.pipe(
    ofType(loadTeacherStaff),
    debugAction(),
    tap(() => updateLoading({ teacherStaff: true })),
    tap((request) => {
      new AddressCheckInstitutionApi(apiConfiguration)
        .getTeacherStaffOfAddressCheckInstitution(request)
        .then((result) => {
          updateTeacherStaff(result);
          updateOriginalTeachers(result.teachers ?? []);
          updateTeacherStaffEditMode(false);
          setTeacherStaffValidationErrors([]);
        })
        .catch((error) => addErrorNotification('address-check-public:error'))
        .finally(() => updateLoading({ teacherStaff: false }));
    })
  );
});

export const updateStep$ = createEffect((actions) => {
  return actions.pipe(
    ofType(updateStep),
    debugAction(),
    tap((request) => {
      new AddressCheckInstitutionApi(apiConfiguration)
        .updateStepOfAddressCheckInstitution(request)
        .catch((error) => addErrorNotification('address-check-public:error'));
    })
  );
});

export const updateLotteryData$ = createEffect((actions) => {
  return actions.pipe(
    ofType(updateLotteryData),
    debugAction(),
    withLatestFrom(lottery$),
    tap((value) => {
      const lottery = {
        ...value[1],
        ...value[0],
      } as LotteryDto & LotteryExtension;
      updateLottery(lottery);
      updateLotteryEditMode(lottery.selected);
    })
  );
});

export const loadLotteryData$ = createEffect((actions) => {
  return actions.pipe(
    ofType(loadLotteryData),
    debugAction(),
    tap(() => updateLoading({ lottery: true })),
    tap((request) => {
      new AddressCheckInstitutionApi(apiConfiguration)
        .getLotteryOfAddressCheckInstitutionRaw(request)
        .then(async (r) => {
          if (r.raw.status === 204) {
            updateLottery({
              selected: false,
              shouldDisplayError: false,
            });
          } else {
            updateLottery({
              ...(await r.value()),
              selected: true,
              shouldDisplayError: false,
            });
          }
        })
        .catch((error) => addErrorNotification('address-check-public:error'))
        .finally(() => updateLoading({ lottery: false }));
    })
  );
});

export const persistLotteryData$ = createEffect((actions) => {
  return actions.pipe(
    ofType(persistLotteryData),
    debugAction(),
    tap((request) => {
      new AddressCheckInstitutionApi(apiConfiguration)
        .createLotteryForAddressCheckInstitution(request)
        .then((lottery) => {
          updateLottery({ ...lottery, selected: true });
          updateLotteryEditMode(false);
        })
        .catch((error) => addErrorNotification('address-check-public:error'));
    })
  );
});

export const deleteLotteryData$ = createEffect((actions) => {
  return actions.pipe(
    ofType(deleteLotteryData),
    debugAction(),
    tap((request) => {
      new AddressCheckInstitutionApi(apiConfiguration)
        .deleteLotteryOfAddressCheckInstitution(request)
        .then((_) => {
          updateLottery({ selected: false });
        })
        .catch((error) => addErrorNotification('address-check-public:error'))
        .finally(() => updateLotteryEditMode(false));
    })
  );
});

export const updateTeacherStaffEffect$ = createEffect((actions) => {
  return actions.pipe(
    ofType(updateTeacherStaffAction),
    debugAction(),
    tap((teacherStaff) => {
      updateTeacherStaffEditMode(true);
      updateTeacherStaff(teacherStaff);
      Object.keys(teacherStaff).forEach((fieldName) =>
        removeTeacherStaffValidationError(
          fieldName as TeacherStaffValidationErrorKey
        )
      );
    })
  );
});

export const persistTeacherStaff$ = createEffect((actions) => {
  return actions.pipe(
    ofType(persistTeacherStaffAction),
    debugAction(),
    tap((request: UpdateTeacherStaffOfAddressCheckInstitutionRequest) => {
      new AddressCheckInstitutionApi(apiConfiguration)
        .updateTeacherStaffOfAddressCheckInstitution(request)
        .then((teacherStaff) => {
          updateTeacherStaffEditMode(false);
          updateOriginalTeachers(teacherStaff.teachers ?? []);
          updateTeacherStaff(teacherStaff);
        })
        .catch((error) => {
          error.response.json().then(setTeacherStaffValidationErrors);
        });
    })
  );
});

export const updateFeedback$ = createEffect((actions) => {
  return actions.pipe(ofType(updateFeedback), debugAction(), tap(setFeedback));
});

export const completeAddressCheck$ = createEffect((actions) => {
  return actions.pipe(
    ofType(completeAddressCheck),
    debugAction(),
    tap(async (request) => {
      if (
        request.addressCheckFeedbackDto?.feedback?.replace(/\s/g, '').length
      ) {
        await new AddressCheckInstitutionApi(apiConfiguration)
          .sendAddressCheckFeedback(request)
          .catch((error) => addErrorNotification('address-check-public:error'));
      }

      await new AddressCheckInstitutionApi(apiConfiguration)
        .updateStepOfAddressCheckInstitution({
          addressCheckInstitutionId: request.addressCheckInstitutionId,
          body: 'DONE',
        })
        .catch((error) => addErrorNotification('address-check-public:error'));

      clearAuth();
      window.location.reload();
    })
  );
});
