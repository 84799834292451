import * as React from 'react';
import {FC, ReactElement, ReactNode, useState} from 'react';
import {Tooltip,} from '@mui/material';
import {ConfirmDialog} from "../confirm-dialog/confirm-dialog";

export const ConfirmAction: FC<{
  tooltip?: string;
  action: () => void;
  label: string;
  content: ReactNode;
  cancel: string;
  confirm: string;
  children: ReactElement;
}> = ({tooltip, action, label, content, cancel, confirm, children}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (confirmed?: boolean) => {
    setOpen(false);
    confirmed && action();
  };

  return (
    <>
      <Tooltip
        title={tooltip}
        placement={'left-end'}
        onClick={handleOpen}
        arrow
      >
        {children}
      </Tooltip>

      <ConfirmDialog title={label} confirm={confirm} cancel={cancel}
                     open={open} handleClose={handleClose} content={content}/>
    </>
  );
};
