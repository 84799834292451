import * as React from 'react';
import { FC, ReactNode } from 'react';
import { Skeleton } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { SkeletonPropsVariantOverrides } from '@mui/material/Skeleton/Skeleton';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const LoadingSkeleton: FC<{
  children: ReactNode;
  loading?: boolean;
  variant?: OverridableStringUnion<
    'text' | 'rectangular' | 'rounded' | 'circular',
    SkeletonPropsVariantOverrides
  >;
  sx?: SxProps<Theme>;
}> = ({ children, loading, variant, sx }) =>
  loading ? (
    <Skeleton
      {...(variant && { variant })}
      sx={{
        display: 'inline-flex',
        flexDirection: 'column',
        position: 'relative',
        maxWidth: '100%',
        ...sx,
      }}
    >
      {children}
    </Skeleton>
  ) : (
    <>{children}</>
  );
