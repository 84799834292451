import { FC, ReactNode, useEffect } from 'react';
import { useObservable } from '@ngneat/react-rxjs';
import { isAuthenticated$, logout$ } from '../../store';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffects } from '@ngneat/effects-hooks';

export const PublicAuthGuard: FC<{ children: ReactNode }> = ({ children }) => {
  useEffects([logout$]);
  const [isAuthenticated] = useObservable(isAuthenticated$);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    !isAuthenticated && navigate('/login' + location.search);
  }, [isAuthenticated, location.search, navigate]);

  return isAuthenticated ? <>{children}</> : null;
};
