import TouchRipple, {
  TouchRippleActions,
} from '@mui/material/ButtonBase/TouchRipple';
import { Box } from '@mui/material';
import * as React from 'react';
import { FC, SyntheticEvent } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

interface BaseCardProps {
  children: React.ReactNode;
  disableRipple?: boolean;
  sx?: SxProps<Theme>;

  [other: string]: unknown;
}

export const BaseCard: FC<BaseCardProps> = ({
  children,
  disableRipple,
  sx,
  ...other
}) => {
  const rippleRef = React.useRef<TouchRippleActions>(null);

  const onRippleStart = (e: SyntheticEvent) => {
    !disableRipple && rippleRef?.current?.start(e);
  };
  const onRippleStop = (e: SyntheticEvent) => {
    !disableRipple && rippleRef?.current?.stop(e);
  };

  return (
    <Box
      onMouseDown={onRippleStart}
      onMouseLeave={onRippleStop}
      onMouseUp={onRippleStop}
      {...other}
      sx={{
        '&& .MuiTouchRipple-rippleVisible': {
          animationDuration: other?.['animationduration'] ?? '600ms',
        },
        ...sx,
      }}
    >
      {!disableRipple && <TouchRipple ref={rippleRef} center={false} />}
      {children}
    </Box>
  );
};
