/* tslint:disable */
/* eslint-disable */
/**
 * AddressCheckPublic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const AddressCheckInstitutionStepDto = {
  WELCOME: 'WELCOME',
  SCHOOL_DATA: 'SCHOOL_DATA',
  TEACHER_LIST: 'TEACHER_LIST',
  TEACHER_INVITATION: 'TEACHER_INVITATION',
  LOTTERY: 'LOTTERY',
  COMPLETION: 'COMPLETION',
  DONE: 'DONE',
} as const;
export type AddressCheckInstitutionStepDto =
  (typeof AddressCheckInstitutionStepDto)[keyof typeof AddressCheckInstitutionStepDto];

export function AddressCheckInstitutionStepDtoFromJSON(
  json: any
): AddressCheckInstitutionStepDto {
  return AddressCheckInstitutionStepDtoFromJSONTyped(json, false);
}

export function AddressCheckInstitutionStepDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddressCheckInstitutionStepDto {
  return json as AddressCheckInstitutionStepDto;
}

export function AddressCheckInstitutionStepDtoToJSON(
  value?: AddressCheckInstitutionStepDto | null
): any {
  return value as any;
}
