import { FC } from 'react';
import { Box, Skeleton } from '@mui/material';
import sanitize from 'sanitize-html';
import { AddressCheckStepProps } from './step-props';

export const WelcomePage: FC<AddressCheckStepProps> = ({
  config,
  configLoading,
  isMobile,
}) => {
  return configLoading ? (
    <>
      <Skeleton variant={'text'} sx={{ width: '40%' }} />
      <br />
      <Skeleton variant={'text'} sx={{ width: '70%' }} />
      <Skeleton variant={'text'} sx={{ width: '100%' }} />
      <Skeleton variant={'text'} sx={{ width: '60%' }} />
      <br />
      <Skeleton variant={'text'} sx={{ width: '80%' }} />
      <Skeleton variant={'text'} />
      <Skeleton variant={'text'} sx={{ width: '70%' }} />
      <Skeleton variant={'text'} sx={{ width: '90%' }} />
      <br />
      <Skeleton variant={'text'} sx={{ width: '75%' }} />
      <Skeleton variant={'text'} sx={{ width: '60%' }} />
      <Skeleton variant={'text'} sx={{ width: '25%' }} />
    </>
  ) : isMobile ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box
        dangerouslySetInnerHTML={{
          __html: sanitize(config.welcomePageBody ?? ''),
        }}
      />
      {!config.lotteryDisabled && (
        <Box
          sx={{
            color: (theme) => theme.palette.text.primary,
            background: (theme) => theme.palette.grey[200],
            py: 0.5,
            px: 2,
            borderRadius: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: sanitize(config.welcomePageMotivator ?? ''),
          }}
        />
      )}
    </Box>
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Box
        sx={{ color: (theme) => theme.palette.text.primary }}
        dangerouslySetInnerHTML={{
          __html: sanitize(config.welcomePageBody ?? ''),
        }}
      />
      {!config.lotteryDisabled && (
        <Box
          sx={{
            color: (theme) => theme.palette.text.primary,
            background: (theme) => theme.palette.grey[100],
            py: 0.5,
            px: 2,
            borderRadius: 2,
            width: '60%',
            alignSelf: 'end',
          }}
          dangerouslySetInnerHTML={{
            __html: sanitize(config.welcomePageMotivator ?? ''),
          }}
        />
      )}
    </Box>
  );
};
