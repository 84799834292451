import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HOSTING_ENVIRONMENT } from '@verona/utils';
import { ConfirmAction } from '@verona/components';
import { Button } from '@mui/material';
import { PersonAdd } from '@mui/icons-material';

export const RegisterTeacher: FC<{ isMobile?: boolean }> = ({ isMobile }) => {
  const { t } = useTranslation('address-check-public', {
    keyPrefix: 'stepper.register',
  });

  const getRegisterLink = () =>
    'staging' === HOSTING_ENVIRONMENT
      ? 'https://profile-stage.veritas.at/profile/user'
      : 'https://profile.veritas.at/profile/user';
  return (
    <ConfirmAction
      action={() => window.open(getRegisterLink())}
      label={t('dialog.label')}
      content={<Trans t={t} i18nKey="dialog.content.label" />}
      cancel={t('dialog.cancel.label')}
      confirm={t('dialog.confirm.label')}
    >
      <Button variant={'contained'} startIcon={<PersonAdd />}>
        {isMobile ? t('open.mobile.label') : t('open.label')}
      </Button>
    </ConfirmAction>
  );
};
