import * as React from 'react';
import { FC } from 'react';
import { MsalConfigLoader } from './MsalConfigLoader';
import { MsalAuthenticator } from './MsalAuthenticator';
import { authenticationIsDisabled } from './TestHelper';

interface MsalAuthProviderProps {
  children: React.ReactNode;
  protectedRoutes: string[];
}

export const MsalAuthProvider: FC<MsalAuthProviderProps> = ({
  children,
  protectedRoutes,
}) => {
  const AADRoutes = ['/signin'];

  const routeIsProtected = () => {
    return [...AADRoutes, ...protectedRoutes].some((route) =>
      window.location.pathname.startsWith(route)
    );
  };

  return authenticationIsDisabled() || !routeIsProtected() ? (
    <>{children}</>
  ) : (
    <MsalConfigLoader>
      <MsalAuthenticator>{children}</MsalAuthenticator>
    </MsalConfigLoader>
  );
};
