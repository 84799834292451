/* tslint:disable */
/* eslint-disable */
/**
 * AddressCheckAdmin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AuthenticationConfig
 */
export interface AuthenticationConfig {
  /**
   *
   * @type {string}
   * @memberof AuthenticationConfig
   */
  authority?: string;
  /**
   *
   * @type {string}
   * @memberof AuthenticationConfig
   */
  clientId?: string;
  /**
   *
   * @type {string}
   * @memberof AuthenticationConfig
   */
  redirectUriPostfix?: string;
  /**
   *
   * @type {string}
   * @memberof AuthenticationConfig
   */
  responseType?: string;
  /**
   *
   * @type {string}
   * @memberof AuthenticationConfig
   */
  scope?: string;
  /**
   *
   * @type {string}
   * @memberof AuthenticationConfig
   */
  silentRedirectUriPostfix?: string;
}

/**
 * Check if a given object implements the AuthenticationConfig interface.
 */
export function instanceOfAuthenticationConfig(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function AuthenticationConfigFromJSON(json: any): AuthenticationConfig {
  return AuthenticationConfigFromJSONTyped(json, false);
}

export function AuthenticationConfigFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AuthenticationConfig {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    authority: !exists(json, 'authority') ? undefined : json['authority'],
    clientId: !exists(json, 'clientId') ? undefined : json['clientId'],
    redirectUriPostfix: !exists(json, 'redirectUriPostfix')
      ? undefined
      : json['redirectUriPostfix'],
    responseType: !exists(json, 'responseType')
      ? undefined
      : json['responseType'],
    scope: !exists(json, 'scope') ? undefined : json['scope'],
    silentRedirectUriPostfix: !exists(json, 'silentRedirectUriPostfix')
      ? undefined
      : json['silentRedirectUriPostfix'],
  };
}

export function AuthenticationConfigToJSON(
  value?: AuthenticationConfig | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    authority: value.authority,
    clientId: value.clientId,
    redirectUriPostfix: value.redirectUriPostfix,
    responseType: value.responseType,
    scope: value.scope,
    silentRedirectUriPostfix: value.silentRedirectUriPostfix,
  };
}
