/* tslint:disable */
/* eslint-disable */
/**
 * AddressCheckPublic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface LotteryDto
 */
export interface LotteryDto {
  /**
   *
   * @type {string}
   * @memberof LotteryDto
   */
  id?: string | null;
  /**
   *
   * @type {number}
   * @memberof LotteryDto
   */
  lotteryParticipantId?: number;
  /**
   *
   * @type {string}
   * @memberof LotteryDto
   */
  lotteryParticipantName?: string;
  /**
   *
   * @type {string}
   * @memberof LotteryDto
   */
  lotteryParticipantEmail?: string;
}

/**
 * Check if a given object implements the LotteryDto interface.
 */
export function instanceOfLotteryDto(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function LotteryDtoFromJSON(json: any): LotteryDto {
  return LotteryDtoFromJSONTyped(json, false);
}

export function LotteryDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LotteryDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    lotteryParticipantId: !exists(json, 'lotteryParticipantId')
      ? undefined
      : json['lotteryParticipantId'],
    lotteryParticipantName: !exists(json, 'lotteryParticipantName')
      ? undefined
      : json['lotteryParticipantName'],
    lotteryParticipantEmail: !exists(json, 'lotteryParticipantEmail')
      ? undefined
      : json['lotteryParticipantEmail'],
  };
}

export function LotteryDtoToJSON(value?: LotteryDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    lotteryParticipantId: value.lotteryParticipantId,
    lotteryParticipantName: value.lotteryParticipantName,
    lotteryParticipantEmail: value.lotteryParticipantEmail,
  };
}
