import React, { FC, ReactNode, useState } from 'react';
import { SxProps } from '@mui/system';
import { Theme, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

interface BorderedSectionProps {
  label?: ReactNode;
  children?: ReactNode;
  sx?: SxProps<Theme>;
  error?: boolean;
  focused?: boolean;
  enableHover?: boolean;
  hoverBorderColor?: string;
}

export const BorderedSection: FC<BorderedSectionProps> = ({
  label,
  children,
  sx,
  error,
  focused,
  enableHover,
  hoverBorderColor,
}) => {
  const theme = useTheme();
  const getBorderColor = () =>
    error
      ? theme.palette.error.main
      : focused
      ? theme.palette.primary.main
      : theme.palette.grey[400];

  const getBorderThickness = () => (focused ? 2 : 1);

  const getTextColor = () =>
    error
      ? theme.palette.error.main
      : focused
      ? theme.palette.primary.main
      : theme.palette.grey[800];

  const [hover, setHover] = useState(false);

  const getHoverStyle = () =>
    hover &&
    !focused &&
    !error && {
      borderColor: hoverBorderColor ?? 'black',
    };

  const MuiBorderHelper: FC<{ sx: SxProps<Theme> }> = ({ sx }) => (
    <Box
      sx={{
        borderTop: getBorderThickness(),
        borderColor: getBorderColor(),
        width: (theme) => theme.spacing(0.9),
        boxSizing: 'border-box',
        ...getHoverStyle(),
        ...sx,
      }}
    />
  );

  return (
    <Box
      onMouseEnter={() => enableHover && setHover(true)}
      onMouseLeave={() => enableHover && setHover(false)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        border: getBorderThickness(),
        borderRadius: 1,
        borderColor: getBorderColor(),
        borderTop: 0,
        boxSizing: 'border-box',
        ...getHoverStyle(),
        position: 'relative',
        m: focused ? 0 : '1px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100% !important',
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <MuiBorderHelper
          sx={{
            width: theme.spacing(0.9),
            borderTopLeftRadius: 2,
          }}
        />
        {label && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              alignItems: 'center',
              width: 'fit-content',
              height: (theme) =>
                focused ? theme.spacing(3.5) : theme.spacing(3.25),
              margin: '-1em 0.5em 0em 0.2em',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              boxSizing: 'border-box',
              px: 0.25,
              color: `${getTextColor()} !important`,
              transition: 'color .2s',
              '*': {
                color: `${getTextColor()} !important`,
                transition: 'color .2s',
              },
            }}
          >
            {label}
          </Box>
        )}
        <MuiBorderHelper
          sx={{
            width: '1em',
            flexGrow: 2,
            borderTopRightRadius: 2,
          }}
        ></MuiBorderHelper>
      </Box>
      <Box
        sx={{
          p: (theme) => theme.spacing(2),
          ...sx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
