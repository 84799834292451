import React, { FC } from 'react';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { inputLabelClasses, Skeleton, TextField } from '@mui/material';

export const TextFieldWithLoading: FC<
  TextFieldProps & { loading?: boolean }
> = (props) => {
  const { loading, ...textFieldProps } = props;

  return loading ? (
    <Skeleton variant={'rounded'} sx={{ height: 53 }} />
  ) : (
    <TextField
      InputLabelProps={{
        sx: {
          [`&.${inputLabelClasses.shrink}`]: {
            [`&.${inputLabelClasses.focused}`]: {
              color: (theme) => theme.palette.text.primary,
            },
          },
        },
      }}
      {...textFieldProps}
    />
  );
};
