import * as React from 'react';
import { FC, useEffect, useState } from 'react';
import { LoadingSkeleton, ScrollablePage } from '@verona/components';
import {
  Box,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Key, School, Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import VeritasLogo from './../assets/veritas_logo.png';
import {
  addressCheckInstitutionIdValidationError$,
  hasLoginError$,
  isAuthenticated$,
  isValidAddressCheckInstitutionId$,
  login,
  login$,
  loginProcessing$,
  validateInstitutionId,
  validateInstitutionId$,
} from '../store';
import { useEffects } from '@ngneat/effects-hooks';
import { dispatch } from '@ngneat/effects';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useObservable } from '@ngneat/react-rxjs';
import { LoadingButton } from '@mui/lab';

export const AddressCheckPublicLoginPage: FC = () => {
  useEffects([login$, validateInstitutionId$]);

  const theme = useTheme();

  const navigate = useNavigate();
  const location = useLocation();

  const [isAuthenticated] = useObservable(isAuthenticated$);

  const [schoolCode, setSchoolCode] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [accessCode, setAccessCode] = useState('');

  const [loginProcessing] = useObservable(loginProcessing$);

  const { t } = useTranslation('address-check-public', { keyPrefix: 'login' });

  const [addressCheckInstitutionIdValidationError] = useObservable(
    addressCheckInstitutionIdValidationError$
  );

  const [isValidAddressCheckInstitutionId] = useObservable(
    isValidAddressCheckInstitutionId$
  );

  const [hasLoginError] = useObservable(hasLoginError$);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(
      validateInstitutionId({
        addressCheckInstitutionId: searchParams.get('code'),
      })
    );
  }, [searchParams]);

  useEffect(() => {
    isAuthenticated && navigate('/' + location.search);
  }, [isAuthenticated]);

  return (
    <ScrollablePage
      sx={{
        p: 0,
        height: '100dvh',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
          background: '#fff',
        },
      }}
    >
      {!isAuthenticated && (
        <Paper
          elevation={2}
          sx={{
            background: '#fff',
            borderRadius: 1,
            display: 'flex',
            flexDirection: 'column',
            mx: 'auto',
            gap: 8,
            width: 512,
            p: 8,
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              p: 4,
              height: '100%',
              gap: 4,
            },
          }}
        >
          <AddressCheckVeritasLogo />

          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
              height: '100%',
              justifyContent: 'center',
              [theme.breakpoints.down('sm')]: {
                gap: 4,
              },
            }}
          >
            <LoadingSkeleton
              loading={isValidAddressCheckInstitutionId === undefined}
            >
              <Typography
                variant={'body1'}
                color={!isValidAddressCheckInstitutionId ? 'error' : ''}
                sx={{ textAlign: 'center' }}
              >
                {isValidAddressCheckInstitutionId
                  ? t('prompt.label')
                  : t(
                      'prompt.error.' +
                        addressCheckInstitutionIdValidationError,
                      'Etwas ist schief gelaufen! Bitte versuchen Sie es später erneut'
                    )}
              </Typography>
            </LoadingSkeleton>

            <Box
              sx={{
                width: '100%',
                mx: 'auto',
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
              }}
            >
              <TextField
                label={t('schoolCode.label')}
                disabled={!isValidAddressCheckInstitutionId}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <School />
                    </InputAdornment>
                  ),
                }}
                value={schoolCode}
                error={hasLoginError}
                onChange={({ currentTarget }) =>
                  setSchoolCode(currentTarget.value)
                }
              />
              <TextField
                label={t('accessCode.label')}
                disabled={!isValidAddressCheckInstitutionId}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                      <Key />
                    </InputAdornment>
                  ),
                }}
                value={accessCode}
                error={hasLoginError}
                onChange={({ currentTarget }) =>
                  setAccessCode(currentTarget.value)
                }
              />
              {hasLoginError && (
                <Typography
                  variant={'body1'}
                  color={'error'}
                  sx={{ textAlign: 'center' }}
                >
                  {t('unauthorized')}
                </Typography>
              )}
            </Box>

            <LoadingButton
              type={'submit'}
              variant={'contained'}
              disabled={!isValidAddressCheckInstitutionId}
              loading={loginProcessing}
              sx={{ maxWidth: '100%' }}
              onClick={() =>
                searchParams.get('code') &&
                dispatch(
                  login({
                    addressCheckInstitutionId: searchParams.get(
                      'code'
                    ) as string,
                    addressCheckInstitutionLoginDto: {
                      schoolCode: schoolCode,
                      authorizationCode: accessCode,
                    },
                  })
                )
              }
            >
              {t('start.label')}
            </LoadingButton>
          </Stack>
        </Paper>
      )}
    </ScrollablePage>
  );
};

const AddressCheckVeritasLogo: FC = () => {
  const { t } = useTranslation('address-check-public', { keyPrefix: 'login' });
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 65,
        }}
      >
        <Box
          component={'img'}
          loading={'lazy'}
          src={VeritasLogo}
          alt="Veritas"
        />
      </Box>
      <Typography
        variant={'h1'}
        sx={{
          [theme.breakpoints.up('sm')]: {
            pt: 0.5,
          },
          textAlign: 'right',
          color: (theme) => theme.palette.grey[900],
        }}
      >
        {t('logo.label')}
      </Typography>
    </Box>
  );
};
