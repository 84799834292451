import { createAction, createEffect, ofType, props } from '@ngneat/effects';
import { tap } from 'rxjs';
import { clearAuth, updateAuth } from './auth.repository';
import { debugAction } from '@verona/utils';
import {
  AddressCheckInstitutionLoginApi,
  ApiResponse,
  LoginRequest,
} from '@verona/address-check-public-api';
import { apiConfiguration } from '../../lib';
import { setHasLoginError } from '../login';

export const login = createAction('[Auth] Login', props<LoginRequest>());

export const login$ = createEffect((actions) => {
  return actions.pipe(
    ofType(login),
    debugAction(),
    tap(() => updateAuth({ loginProcessing: true })),
    tap((loginRequest) => {
      new AddressCheckInstitutionLoginApi(apiConfiguration)
        .loginRaw(loginRequest)
        .then(async (result: ApiResponse<string>) => {
          updateAuth({ token: await result.value() });
          setHasLoginError(false);
        })
        .catch(() => setHasLoginError(true))
        .finally(() => updateAuth({ loginProcessing: false }));
    })
  );
});

export const logout = createAction('[Auth] Logout');

export const logout$ = createEffect((actions) => {
  return actions.pipe(
    ofType(logout),
    debugAction(),
    tap(() => {
      clearAuth();
      window.location.reload();
    })
  );
});
