import * as React from 'react';
import { FC, useEffect } from 'react';
import { Configuration, LogLevel } from '@azure/msal-browser';
import { dispatch } from '@ngneat/effects';
import { useEffects } from '@ngneat/effects-hooks';
import {
  fetchAuthConfig,
  fetchAuthConfigSettings$,
  setAuth,
  setAuth$,
} from './stores/auth.effects';

import {
  authConfig$,
  authConfigError$,
  authConfigSettings$,
} from './stores/auth.repository';
import { useObservable } from '@ngneat/react-rxjs';
import { LoadingIndicator } from '@verona/components';
import { Box, Typography } from '@mui/material';
import { AuthenticationConfig } from '@verona/address-check-admin-api';
import { useTranslation } from 'react-i18next';

interface Props {
  children: React.ReactElement;
}

export const MsalConfigLoader: FC<Props> = (props) => {
  useEffects([fetchAuthConfigSettings$, setAuth$]);
  const [authConfig] = useObservable(authConfigSettings$);
  const [config] = useObservable(authConfig$);
  const [errorMessage] = useObservable(authConfigError$);

  useEffect(() => {
    dispatch(fetchAuthConfig);
  }, []);

  useEffect(() => {
    dispatch(setAuth(mapAppSettingsToConfiguration(authConfig)));
  }, [authConfig.authority]);

  function mapAppSettingsToConfiguration(
    ss: AuthenticationConfig
  ): Configuration {
    return {
      auth: {
        authority: ss.authority,
        clientId: ss.clientId ?? '',
        redirectUri: `${window.location.origin}/${ss.redirectUriPostfix}`,
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
      },

      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case LogLevel.Error:
                window.console.error(message);
                return;
              case LogLevel.Info:
                window.console.info(message);
                return;
              case LogLevel.Verbose:
                window.console.debug(message);
                return;
              case LogLevel.Warning:
                window.console.warn(message);
                return;
            }
          },
        },
      },
    };
  }

  return config?.auth?.clientId ? (
    props.children
  ) : (
    <MsalConfigLoaderInfo errorMessage={errorMessage}></MsalConfigLoaderInfo>
  );
};

export const MsalConfigLoaderInfo: FC<{ errorMessage?: string }> = ({
  errorMessage,
}) => {
  const { t } = useTranslation('address-check');
  return (
    <Box>
      {errorMessage ? (
        <Box sx={{ p: 2 }}>
          <Typography variant={'h3'}>
            Fehler beim Laden der OIDC Config
          </Typography>
          <Typography>{errorMessage?.toString()}</Typography>
        </Box>
      ) : (
        <LoadingIndicator
          loading={true}
          type={'linear'}
          infoText={t('loading.identityProviderConfig') as string}
        />
      )}
    </Box>
  );
};
