/* tslint:disable */
/* eslint-disable */
/**
 * AddressCheckPublic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AddressCheckInstitutionLoginDto
 */
export interface AddressCheckInstitutionLoginDto {
  /**
   *
   * @type {string}
   * @memberof AddressCheckInstitutionLoginDto
   */
  authorizationCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof AddressCheckInstitutionLoginDto
   */
  schoolCode?: string | null;
}

/**
 * Check if a given object implements the AddressCheckInstitutionLoginDto interface.
 */
export function instanceOfAddressCheckInstitutionLoginDto(
  value: object
): boolean {
  let isInstance = true;

  return isInstance;
}

export function AddressCheckInstitutionLoginDtoFromJSON(
  json: any
): AddressCheckInstitutionLoginDto {
  return AddressCheckInstitutionLoginDtoFromJSONTyped(json, false);
}

export function AddressCheckInstitutionLoginDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddressCheckInstitutionLoginDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    authorizationCode: !exists(json, 'authorizationCode')
      ? undefined
      : json['authorizationCode'],
    schoolCode: !exists(json, 'schoolCode') ? undefined : json['schoolCode'],
  };
}

export function AddressCheckInstitutionLoginDtoToJSON(
  value?: AddressCheckInstitutionLoginDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    authorizationCode: value.authorizationCode,
    schoolCode: value.schoolCode,
  };
}
