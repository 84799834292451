import { createAction, props } from '@ngneat/effects';
import {
  AddressCheckFeedbackDto,
  CreateLotteryForAddressCheckInstitutionRequest,
  DeleteLotteryOfAddressCheckInstitutionRequest,
  GetLotteryOfAddressCheckInstitutionRequest,
  GetSchoolDataRequest,
  GetTeacherStaffOfAddressCheckInstitutionRequest,
  LotteryDto,
  SendAddressCheckFeedbackRequest,
  TeacherStaffDto,
  UpdateSchoolDataRequest,
  UpdateStepOfAddressCheckInstitutionRequest,
  UpdateTeacherStaffOfAddressCheckInstitutionRequest,
} from '@verona/address-check-public-api';
import { SchoolDataValidationErrorKey } from './address-check.definitions';
import { LotteryExtension } from './address-check.repository';

export const initializeAddressCheck = createAction(
  '[Addresscheck Public] Initializing Addresscheck',
  props<{ addressCheckInstitutionId: string }>()
);

export const loadSchoolData = createAction(
  '[Addresscheck Public] Load School Data',
  props<GetSchoolDataRequest>()
);

export const removeSchoolDataValidationErrorAction = createAction(
  '[Addresscheck Public] Remove School Data Validation Error',
  props<{
    fieldName: SchoolDataValidationErrorKey;
  }>()
);

export const setSchoolDataEditMode = createAction(
  '[Addresscheck Public] Set School Data Edit Mode',
  props<{ editMode: boolean }>()
);

export const updateSchoolData = createAction(
  '[Addresscheck Public] Edit School Data',
  props<UpdateSchoolDataRequest>()
);

export const reloadTeachers = createAction(
  '[Addresscheck Public] Reload Teacher in teacher Staff',
  props<GetTeacherStaffOfAddressCheckInstitutionRequest>()
);

export const loadTeacherStaff = createAction(
  '[Addresscheck Public] Load Teacher Staff',
  props<GetTeacherStaffOfAddressCheckInstitutionRequest>()
);

export const updateLotteryData = createAction(
  '[Addresscheck Public] Update LotteryData',
  props<Partial<LotteryDto & LotteryExtension>>()
);

export const loadLotteryData = createAction(
  '[Addresscheck Public] Load LotteryData',
  props<GetLotteryOfAddressCheckInstitutionRequest>()
);

export const persistLotteryData = createAction(
  '[Addresscheck Public] Create/Update Lottery',
  props<CreateLotteryForAddressCheckInstitutionRequest>()
);

export const deleteLotteryData = createAction(
  '[Addresscheck Public] Delete Lottery',
  props<DeleteLotteryOfAddressCheckInstitutionRequest>()
);

export const updateTeacherStaffAction = createAction(
  '[Addresscheck Public] Update TeacherStaff',
  props<TeacherStaffDto>()
);

export const persistTeacherStaffAction = createAction(
  '[Addresscheck Public] Persist TeacherStaff',
  props<UpdateTeacherStaffOfAddressCheckInstitutionRequest>()
);

export const updateFeedback = createAction(
  '[Addresscheck Public] Update Feedback',
  props<AddressCheckFeedbackDto>()
);

export const completeAddressCheck = createAction(
  '[Addresscheck Public] Complete AddressCheck',
  props<SendAddressCheckFeedbackRequest>()
);

export const updateStep = createAction(
  '[Addresscheck Public] Update Step',
  props<UpdateStepOfAddressCheckInstitutionRequest>()
);
