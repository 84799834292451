import * as React from 'react';
import { FC, ReactNode } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export const ConfirmDialog: FC<{
  title: string;
  cancel?: string;
  confirm: string;
  open: boolean;
  handleClose: (confirmed?: boolean) => void;
  content: ReactNode;
}> = ({ title, confirm, cancel, open, handleClose, content }) => {
  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle sx={{ pt: 4, pl: 4 }}>{title}</DialogTitle>
      <DialogContent sx={{ p: 4 }}>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ pb: 2, pr: 2 }}>
        {cancel && (
          <Button variant={'outlined'} onClick={() => handleClose(false)}>
            {cancel}
          </Button>
        )}
        <Button variant={'contained'} onClick={() => handleClose(true)}>
          {confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
