import { FC, useEffect, useState } from 'react';
import { useObservable } from '@ngneat/react-rxjs';
import {
  authToken$,
  completeAddressCheck$,
  deleteLotteryData$,
  initializeAddressCheck$,
  loadLotteryData$,
  loadSchoolData$,
  loadTeacherStaff$,
  logout,
  persistLotteryData$,
  persistTeacherStaff$,
  reloadTeachers$,
  removeSchoolDataValidationError$,
  setSchoolDataEditMode$,
  updateFeedback$,
  updateLotteryData$,
  updateSchoolData$,
  updateStep$,
  updateTeacherStaffEffect$,
} from '../store';
import jwt_decode from 'jwt-decode';
import { AddressCheckStepper, PublicHeader } from '../lib';
import { useEffects } from '@ngneat/effects-hooks';
import { useSearchParams } from 'react-router-dom';
import { dispatch } from '@ngneat/effects';
import { Box, useTheme } from '@mui/material';
import { initializeAddressCheck } from '../store/address-check/address-check.actions';

export interface TokenBody {
  addressCheckInstitutionId: string;
  schoolCode: string;
  authorizationCode: string;
  addressNumber: string;
  exp: number;
  iss: string;
  aud: string;
}

export const AddressCheckPublicPage: FC = () => {
  const theme = useTheme();
  useEffects([
    initializeAddressCheck$,
    loadTeacherStaff$,
    updateLotteryData$,
    persistLotteryData$,
    deleteLotteryData$,
    loadLotteryData$,
    reloadTeachers$,
    loadSchoolData$,
    updateSchoolData$,
    removeSchoolDataValidationError$,
    setSchoolDataEditMode$,
    updateTeacherStaffEffect$,
    updateStep$,
    updateFeedback$,
    persistTeacherStaff$,
    completeAddressCheck$,
  ]);

  const [token] = useObservable(authToken$);
  const [decodedBody, setDecodedBody] = useState({} as TokenBody);

  useEffect(() => {
    token && setDecodedBody(jwt_decode<TokenBody>(token));
  }, [token]);

  const [params] = useSearchParams();

  useEffect(() => {
    const code = params.get('code');
    if (!code) return;

    dispatch(
      initializeAddressCheck({
        addressCheckInstitutionId: code,
      })
    );
  }, [params]);

  useEffect(() => {
    if (
      !!decodedBody?.addressCheckInstitutionId &&
      decodedBody?.addressCheckInstitutionId !== params.get('code')
    ) {
      dispatch(logout());
    }
  }, [params, decodedBody]);

  return (
    <Box
      sx={{
        [theme.breakpoints.up('md')]: {
          height: '100dvh',
          borderRadius: 0,
          mx: 'auto',
        },
        [theme.breakpoints.down('md')]: {
          overflow: 'hidden',
          height: '100%',
        },
      }}
    >
      <PublicHeader tokenBody={decodedBody} />

      <AddressCheckStepper />
    </Box>
  );
};

export default AddressCheckPublicPage;
