import {
  addEntities,
  deleteEntities,
  selectAllEntities,
  withEntities,
} from '@ngneat/elf-entities';
import { createStore } from '@ngneat/elf';
import { v4 as uuid } from 'uuid';
import { $Dictionary } from 'i18next/typescript/helpers';

interface Notification {
  id: string;
  notificationTranslation: Translation;
  notificationType: NotificationType;
}

export const NotificationType = {
  SUCCESS: 'success',
  ERROR: 'error',
} as const;

export type NotificationType =
  (typeof NotificationType)[keyof typeof NotificationType];

export interface Translation {
  key: string;
  properties?: $Dictionary;
}

const notificationStore = createStore(
  { name: 'notification' },
  withEntities<Notification>()
);

export const notifications$ = notificationStore.pipe(selectAllEntities());

export function addSuccessNotification(
  translationKey: string,
  translationProperties = {}
) {
  notificationStore.update(
    addEntities({
      id: uuid(),
      notificationType: NotificationType.SUCCESS,
      notificationTranslation: {
        key: translationKey,
        properties: translationProperties,
      },
    })
  );
}

export function addErrorNotification(
  translationKey: string,
  translationProperties = {}
) {
  notificationStore.update(
    addEntities({
      id: uuid(),
      notificationType: NotificationType.ERROR,
      notificationTranslation: {
        key: translationKey,
        properties: translationProperties,
      },
    })
  );
}

export function removeNotification(id: string) {
  notificationStore.update(deleteEntities(id));
}
