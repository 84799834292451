import { createAction, createEffect, ofType, props } from '@ngneat/effects';
import { map, tap } from 'rxjs/operators';
import { updateAuth } from './auth.repository';
import {
  AuthenticationApi,
  Configuration,
} from '@verona/address-check-admin-api';

const configuration = new Configuration({ basePath: '/api' });

export const setAuth = createAction(
  '[Auth] Set Is Authenticated',
  props<any>()
);

export const setAuth$ = createEffect((actions) =>
  actions.pipe(ofType(setAuth), tap(updateAuth))
);

export const fetchAuthConfig = createAction(
  '[Auth] fetch config',
  props<any>()
);
export const fetchAuthConfigSettings$ = createEffect((actions) =>
  actions.pipe(
    ofType(fetchAuthConfig),
    map(() => {
      try {
        new AuthenticationApi(configuration).configAuthenticationGet().then(
          (config) => {
            updateAuth(config);
          },
          (error) => updateAuth({ authConfigError: error })
        );
      } catch (e) {
        updateAuth({ authConfigError: (e as string).toString() });
      }
    })
  )
);
