import * as React from 'react';
import { FC } from 'react';
import { grey, red } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material';

export const veronaColors = {
  50: '#fffde6',
  100: '#fff8c1',
  200: '#fff398',
  300: '#ffee6e',
  400: '#ffe94c',
  500: '#ffe424',
  600: '#ffdd33',
  700: '#FFD500',
  800: '#FFBF00',
  900: '#fa780c',
};

const theme = createTheme({
  palette: {
    tonalOffset: 0,
    background: {
      default: '#fff',
    },
    primary: {
      light: veronaColors[50],
      main: veronaColors[700],
      dark: veronaColors[800],
      contrastText: grey[800],
    },
    secondary: {
      main: grey[100],
      dark: grey[200],
      contrastText: grey[800],
    },
    text: {
      primary: grey[800],
      secondary: grey[700],
    },
    error: {
      main: red[400],
      light: red[50],
    },
  },
  typography: {
    fontSize: 12.25,
    fontFamily:
      "'Hanken Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    h1: {
      fontSize: '24px',
      fontWeight: 500,
    },
    h2: {
      fontSize: '21px',
      fontWeight: 500,
    },
    h3: {
      fontSize: '16px',
      fontWeight: 500,
    },
    h4: {
      fontSize: '12px',
      fontWeight: 500,
    },
    allVariants: {
      color: grey[800],
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#fff',
        },
        b: {
          fontWeight: 500,
        },
        strong: {
          fontWeight: 500,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: 17,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          boxShadow: 'none',
          fontWeight: 500,
          // textTransform: "none",
        },
        text: {
          root: {
            '&MuiButtonBase': {
              background: 'none',
            },
          },
        },
      },
      // outlined: {
      //   textTransform: "none",
      // },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          bottom: 0,
          top: 0,
          height: 'auto',
          borderRadius: 4,
          margin: 16,
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        lineHorizontal: { display: 'none' },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
});

export const PublicThemeProvider: FC<{
  children?: React.ReactNode;
}> = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;
