/* tslint:disable */
/* eslint-disable */
/**
 * AddressCheckPublic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddressCheckInstitutionTeacherDto } from './AddressCheckInstitutionTeacherDto';
import {
  AddressCheckInstitutionTeacherDtoFromJSON,
  AddressCheckInstitutionTeacherDtoFromJSONTyped,
  AddressCheckInstitutionTeacherDtoToJSON,
} from './AddressCheckInstitutionTeacherDto';

/**
 *
 * @export
 * @interface TeacherStaffDto
 */
export interface TeacherStaffDto {
  /**
   *
   * @type {AddressCheckInstitutionTeacherDto}
   * @memberof TeacherStaffDto
   */
  headmaster?: AddressCheckInstitutionTeacherDto;
  /**
   *
   * @type {Array<AddressCheckInstitutionTeacherDto>}
   * @memberof TeacherStaffDto
   */
  schoolBookManagers?: Array<AddressCheckInstitutionTeacherDto>;
  /**
   *
   * @type {Array<AddressCheckInstitutionTeacherDto>}
   * @memberof TeacherStaffDto
   */
  teachers?: Array<AddressCheckInstitutionTeacherDto>;
}

/**
 * Check if a given object implements the TeacherStaffDto interface.
 */
export function instanceOfTeacherStaffDto(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function TeacherStaffDtoFromJSON(json: any): TeacherStaffDto {
  return TeacherStaffDtoFromJSONTyped(json, false);
}

export function TeacherStaffDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TeacherStaffDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    headmaster: !exists(json, 'headmaster')
      ? undefined
      : AddressCheckInstitutionTeacherDtoFromJSON(json['headmaster']),
    schoolBookManagers: !exists(json, 'schoolBookManagers')
      ? undefined
      : (json['schoolBookManagers'] as Array<any>).map(
          AddressCheckInstitutionTeacherDtoFromJSON
        ),
    teachers: !exists(json, 'teachers')
      ? undefined
      : (json['teachers'] as Array<any>).map(
          AddressCheckInstitutionTeacherDtoFromJSON
        ),
  };
}

export function TeacherStaffDtoToJSON(value?: TeacherStaffDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    headmaster: AddressCheckInstitutionTeacherDtoToJSON(value.headmaster),
    schoolBookManagers:
      value.schoolBookManagers === undefined
        ? undefined
        : (value.schoolBookManagers as Array<any>).map(
            AddressCheckInstitutionTeacherDtoToJSON
          ),
    teachers:
      value.teachers === undefined
        ? undefined
        : (value.teachers as Array<any>).map(
            AddressCheckInstitutionTeacherDtoToJSON
          ),
  };
}
