import { Box, SxProps, Theme } from '@mui/material';
import { forwardRef, ReactNode } from 'react';

interface ScrollablePageProps {
  children?: ReactNode;
  sx?: SxProps<Theme>;
  reachedBottom?: () => void;
}

export const ScrollablePage = forwardRef(
  ({ children, sx, reachedBottom }: ScrollablePageProps, ref) => {
    const checkForBottom = (box: HTMLElement) =>
      isBottom(box) && reachedBottom?.();

    const isBottom = (box: HTMLElement) =>
      Math.ceil(box.scrollTop) >=
      Math.floor(box.scrollHeight - box.offsetHeight);

    return (
      <Box
        ref={ref}
        sx={{
          width: '100%',
          height: 'calc(100vh - 56px)',
          scrollbarGutter: (theme) => theme.spacing(0.8),
          pt: (theme) => theme.spacing(4),
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            width: (theme) => theme.spacing(0.8),
            height: (theme) => theme.spacing(0.8),
          },
          '&::-webkit-scrollbar-track': {},
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: (theme) => theme.palette.grey[400],
            borderRadius: 1000,
            '&:hover': {
              backgroundColor: (theme) => theme.palette.grey[500],
            },
          },
          ...sx,
        }}
        onScroll={(it) => checkForBottom(it.currentTarget)}
      >
        {children}
      </Box>
    );
  }
);
