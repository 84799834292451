import { tap } from 'rxjs/operators';

const colors = {
  yellow: 'color: #E9C216',
  green: 'color: #17aF17',
};

const debugStyles = {
  Load: colors.yellow,
  Set: colors.green,
  Update: colors.green,
  Select: colors.green,
};

export function debugAction<T>() {
  return tap((action: T) => {
    const { type, ...rest } = action as { type: string };
    console.log(
      '%c %s %o',
      debugStyles[type.split(' ')[1] as keyof typeof debugStyles],
      type,
      rest
    );
  });
}
