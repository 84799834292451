import React, { FC, ReactNode, useState } from 'react';
import { FileCopy } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { addSuccessNotification } from '@verona/notifications';
import { BorderedSection } from '../bordered-section/bordered-section';
import { BaseCard } from '../base-card/base-card';

export const CopyableContainer: FC<{
  children: ReactNode;
  copyValue: string;
  notificationLabel: string;
  hoverLabel: string;
  label: string;
}> = ({ children, copyValue, notificationLabel, hoverLabel, label }) => {
  const [hover, setHover] = useState(false);

  return (
    <BaseCard
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => {
        navigator.clipboard
          .writeText(copyValue ?? '')
          .then((it) => addSuccessNotification(notificationLabel + '.copied'));
      }}
      sx={{
        transition: '.2s background-color',
        '&:hover': {
          backgroundColor: (theme) => theme.palette.grey[50],
        },
        borderRadius: 1,
      }}
    >
      <BorderedSection
        enableHover
        hoverBorderColor={'transparent'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 2,
          pt: 1,
          m: 0,
          cursor: 'pointer',
          borderColor: hover ? 'red' : 'green',
          userSelect: 'none',
        }}
        label={label}
      >
        <Box
          sx={{
            userSelect: 'none',
            '&:hover': {
              transition: '.2s filter',
            },
            color: (theme) => theme.palette.grey[hover ? 400 : 700],
            ...(hover && { filter: 'blur(2px)' }),
          }}
        >
          {children}
        </Box>

        {hover ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              gap: 0.5,
            }}
          >
            <Typography variant={'h3'}>{hoverLabel}</Typography>
            <FileCopy />
          </Box>
        ) : (
          <FileCopy
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              mt: 2,
              mr: 1.5,
              color: (theme) => theme.palette.grey[600],
            }}
          />
        )}
      </BorderedSection>
    </BaseCard>
  );
};
