import { createStore, select, withProps } from '@ngneat/elf';
import { AccountInfo, Configuration } from '@azure/msal-browser';
import { authenticationIsDisabled } from '../TestHelper';
import { AuthenticationConfig } from '@verona/address-check-admin-api';

export interface AuthTokens {
  accessToken: string;
  idToken: string;
}

export interface IsAuthenticated {
  isAuthenticated: boolean;
}

export interface AuthMethods {
  login: () => Promise<void>;
  logout: () => void;
}

export interface AuthConfigError {
  authConfigError: string | undefined;
}

export type AuthStoreProps = Partial<
  AuthTokens &
    IsAuthenticated &
    AccountInfo &
    AuthMethods &
    AuthenticationConfig & // authConfigSettings
    Configuration & // authConfig
    AuthConfigError
>;

const authStore = createStore(
  { name: 'auth' },
  withProps<AuthStoreProps>({} as AuthStoreProps)
);

export const idToken$ = authStore.pipe(select((state) => state.idToken ?? ''));
export const isAuthenticated$ = authStore.pipe(
  select((state) => state.isAuthenticated || authenticationIsDisabled())
);
export const accountInfo$ = authStore.pipe(
  select(
    (state) =>
      ({
        environment: state.environment,
        homeAccountId: state.homeAccountId,
        idTokenClaims: state.idTokenClaims,
        localAccountId: state.localAccountId,
        name: state.name,
        nativeAccountId: state.nativeAccountId,
        username: state.username,
        tenantId: state.tenantId,
      } as AccountInfo)
  )
);

export const authConfigSettings$ = authStore.pipe(
  select(
    (state) =>
      ({
        authority: state.authority,
        clientId: state.clientId,
        redirectUriPostfix: state.redirectUriPostfix,
        responseType: state.responseType,
        scope: state.scope,
        silentRedirectUriPostfix: state.silentRedirectUriPostfix,
      } as AuthenticationConfig)
  )
);
export const authConfigError$ = authStore.pipe(
  select((state) => state.authConfigError)
);
export const authConfig$ = authStore.pipe(
  select(
    (state) =>
      ({
        auth: state.auth,
        cache: state.cache,
        system: state.system,
        telemetry: state.telemetry,
      } as Configuration)
  )
);

export const updateAuth = (newState: AuthStoreProps) =>
  authStore.update((state) => ({
    ...state,
    ...newState,
  }));
