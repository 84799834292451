/* tslint:disable */
/* eslint-disable */
/**
 * AddressCheckAdmin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { AuthenticationConfig } from '../models/index';
import { AuthenticationConfigFromJSON } from '../models/index';

/**
 *
 */
export class AuthenticationApi extends runtime.BaseAPI {
  /**
   */
  async configAuthenticationGetRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AuthenticationConfig>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'adminAuth',
        []
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('publicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/config/authentication`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AuthenticationConfigFromJSON(jsonValue)
    );
  }

  /**
   */
  async configAuthenticationGet(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AuthenticationConfig> {
    const response = await this.configAuthenticationGetRaw(initOverrides);
    return await response.value();
  }
}
