import { FC } from 'react';
import { Box, Skeleton, TextField } from '@mui/material';
import { AddressCheckStepProps } from './step-props';
import { feedback$ } from '../../store';
import { useTranslation } from 'react-i18next';
import { useObservable } from '@ngneat/react-rxjs';
import { dispatch } from '@ngneat/effects';
import { updateFeedback } from '../../store/address-check/address-check.actions';

export const CompletionPage: FC<AddressCheckStepProps> = ({
  config,
  configLoading,
  isMobile,
  addressCheckInstitutionId,
}) => {
  const { t } = useTranslation('address-check-public', {
    keyPrefix: 'steps.completion',
  });
  const [feedback] = useObservable(feedback$);

  return configLoading ? (
    <Skeleton variant={'text'} sx={{ width: '40%' }} />
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'center',
        width: '100%',
        ...(isMobile && { mt: 5 }),
      }}
    >
      <Box
        sx={{
          color: (theme) => theme.palette.text.primary,
        }}
        dangerouslySetInnerHTML={{
          __html: config.completionPageBody ?? '',
        }}
      />

      <TextField
        multiline
        minRows={isMobile ? 6 : 3}
        sx={{ maxWidth: 768 }}
        fullWidth
        placeholder={t('feedback.placeholder.label') as string}
        value={feedback.feedback}
        onChange={(e) => dispatch(updateFeedback({ feedback: e.target.value }))}
      />
    </Box>
  );
};
