import React, { FC, useEffect, useState } from 'react';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { TextFieldWithLoading } from '../textfield-with-loading/textfield-with-loading';

export const PhoneNumberInput: FC<TextFieldProps & { loading?: boolean }> = (
  props
) => {
  const [displayPhoneNumber, setDisplayPhoneNumber] = useState('');

  useEffect(() => {
    if (typeof props.value === 'string') {
      setDisplayPhoneNumber(formatPhoneNumberToDisplayString(props.value));
    }
  }, [props.value]);

  function splitPhonePrefix(result: string) {
    if (result.length > 7) {
      return result.slice(0, 7) + ' ' + result.slice(7);
    }
    return result;
  }

  function formatPhoneNumberToDisplayString(input: string) {
    return splitPhonePrefix(formatPhoneNumber(input));
  }

  function formatPhoneNumber(input: string) {
    const inputWithoutSpaces = input.replace(/\s/g, '');
    let result = '';

    if (inputWithoutSpaces.match(/^\+\d{0,2}/)) {
      result =
        inputWithoutSpaces.slice(0, 3) + ' ' + inputWithoutSpaces.slice(3);
    } else if (inputWithoutSpaces.startsWith('0')) {
      result = '+43 ' + inputWithoutSpaces.slice(1);
    } else if (inputWithoutSpaces !== '') {
      result = '+43 ' + inputWithoutSpaces;
    }
    return result;
  }

  return (
    <TextFieldWithLoading
      {...props}
      onBlur={(event) => {
        setDisplayPhoneNumber(
          formatPhoneNumberToDisplayString(event.target.value)
        );

        if (props.onChange) {
          props.onChange({
            ...event,
            target: {
              ...event.target,
              value: formatPhoneNumber(event.target.value),
            },
          });
        }
      }}
      onChange={(event) => {
        setDisplayPhoneNumber(event.target.value);
      }}
      value={displayPhoneNumber}
    />
  );
};
