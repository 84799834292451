import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { TokenBody } from '../../page';
import VeritasLogo from './../../assets/veritas_logo.png';
import { useTranslation } from 'react-i18next';
import { Info } from '@mui/icons-material';
import { ConfirmDialog } from '@verona/components';
import sanitize from 'sanitize-html';

export interface PublicHeaderProps {
  tokenBody?: TokenBody;
}

export const PublicHeader: FC<PublicHeaderProps> = ({ tokenBody }) => {
  const theme = useTheme();
  const { t } = useTranslation('address-check-public', { keyPrefix: 'header' });
  const [showAddressCheckInfoDialog, setShowAddressCheckInfoDialog] =
    useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Paper
      sx={{
        borderRadius: 0,
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.2)',
        zIndex: 100,
      }}
    >
      <Box
        sx={{
          maxWidth: 1268,
          width: '100%',
          position: 'sticky',
          display: 'block',
          [theme.breakpoints.up('md')]: {
            height: 120,
            px: 4,
            py: 2.5,
          },
          [theme.breakpoints.down('md')]: {
            height: 80,
          },
        }}
      >
        <Box
          component={'img'}
          src={VeritasLogo}
          alt="Veritas"
          sx={{
            position: 'absolute',
            [theme.breakpoints.up('md')]: {
              height: 80,
            },
            [theme.breakpoints.down('md')]: {
              height: 50,
              top: 15,
              left: 15,
            },
          }}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            position: 'absolute',
            right: 25,
            [theme.breakpoints.up('md')]: {
              top: 45,
            },
            [theme.breakpoints.down('md')]: {
              top: 25,
            },
          }}
        >
          <Typography
            variant="h3"
            sx={{ color: (theme) => theme.palette.grey[600] }}
          >
            {`${t('schoolCode.label')}: ${tokenBody?.schoolCode}`}
          </Typography>
          <Button
            endIcon={!isMobile && <Info />}
            variant="contained"
            sx={{
              [theme.breakpoints.down('md')]: {
                p: 0,
                pr: theme.spacing(1),
                pl: theme.spacing(1),
              },
            }}
            onClick={() => setShowAddressCheckInfoDialog(true)}
          >
            {t('benefits.label')}
          </Button>
        </Box>
      </Box>
      <ConfirmDialog
        title={t(`benefits.dialog.label`)}
        confirm={t(`benefits.dialog.confirm`)}
        open={showAddressCheckInfoDialog}
        handleClose={() => {
          setShowAddressCheckInfoDialog(false);
        }}
        content={
          <Box
            dangerouslySetInnerHTML={{
              __html: sanitize(t('benefits.dialog.content')),
            }}
          />
        }
      />
    </Paper>
  );
};
