/* tslint:disable */
/* eslint-disable */
/**
 * AddressCheckPublic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  AddressCheckConfigurationDto,
  AddressCheckFeedbackDto,
  AddressCheckInstitutionStepDto,
  LotteryDto,
  SchoolDataDto,
  SchoolDataValidationErrorDto,
  TeacherStaffDto,
  TeacherStaffValidationErrorDto,
} from '../models/index';
import {
  AddressCheckConfigurationDtoFromJSON,
  AddressCheckConfigurationDtoToJSON,
  AddressCheckFeedbackDtoFromJSON,
  AddressCheckFeedbackDtoToJSON,
  AddressCheckInstitutionStepDtoFromJSON,
  AddressCheckInstitutionStepDtoToJSON,
  LotteryDtoFromJSON,
  LotteryDtoToJSON,
  SchoolDataDtoFromJSON,
  SchoolDataDtoToJSON,
  SchoolDataValidationErrorDtoFromJSON,
  SchoolDataValidationErrorDtoToJSON,
  TeacherStaffDtoFromJSON,
  TeacherStaffDtoToJSON,
  TeacherStaffValidationErrorDtoFromJSON,
  TeacherStaffValidationErrorDtoToJSON,
} from '../models/index';

export interface CreateLotteryForAddressCheckInstitutionRequest {
  addressCheckInstitutionId: string;
  lotteryDto?: LotteryDto;
}

export interface DeleteLotteryOfAddressCheckInstitutionRequest {
  addressCheckInstitutionId: string;
}

export interface GetAddressCheckConfigurationRequest {
  addressCheckInstitutionId: string;
}

export interface GetLotteryOfAddressCheckInstitutionRequest {
  addressCheckInstitutionId: string;
}

export interface GetSchoolDataRequest {
  addressCheckInstitutionId: string;
}

export interface GetStepOfAddressCheckInstitutionRequest {
  addressCheckInstitutionId: string;
}

export interface GetTeacherStaffOfAddressCheckInstitutionRequest {
  addressCheckInstitutionId: string;
}

export interface SendAddressCheckFeedbackRequest {
  addressCheckInstitutionId: string;
  addressCheckFeedbackDto?: AddressCheckFeedbackDto;
}

export interface UpdateSchoolDataRequest {
  addressCheckInstitutionId: string;
  schoolDataDto?: SchoolDataDto;
}

export interface UpdateStepOfAddressCheckInstitutionRequest {
  addressCheckInstitutionId: string;
  body?: string;
}

export interface UpdateTeacherStaffOfAddressCheckInstitutionRequest {
  addressCheckInstitutionId: string;
  teacherStaffDto?: TeacherStaffDto;
}

/**
 *
 */
export class AddressCheckInstitutionApi extends runtime.BaseAPI {
  /**
   */
  async createLotteryForAddressCheckInstitutionRaw(
    requestParameters: CreateLotteryForAddressCheckInstitutionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<LotteryDto>> {
    if (
      requestParameters.addressCheckInstitutionId === null ||
      requestParameters.addressCheckInstitutionId === undefined
    ) {
      throw new runtime.RequiredError(
        'addressCheckInstitutionId',
        'Required parameter requestParameters.addressCheckInstitutionId was null or undefined when calling createLotteryForAddressCheckInstitution.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('token', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/public/v1/institution/{addressCheckInstitutionId}/lottery`.replace(
          `{${'addressCheckInstitutionId'}}`,
          encodeURIComponent(
            String(requestParameters.addressCheckInstitutionId)
          )
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: LotteryDtoToJSON(requestParameters.lotteryDto),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LotteryDtoFromJSON(jsonValue)
    );
  }

  /**
   */
  async createLotteryForAddressCheckInstitution(
    requestParameters: CreateLotteryForAddressCheckInstitutionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<LotteryDto> {
    const response = await this.createLotteryForAddressCheckInstitutionRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   */
  async deleteLotteryOfAddressCheckInstitutionRaw(
    requestParameters: DeleteLotteryOfAddressCheckInstitutionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.addressCheckInstitutionId === null ||
      requestParameters.addressCheckInstitutionId === undefined
    ) {
      throw new runtime.RequiredError(
        'addressCheckInstitutionId',
        'Required parameter requestParameters.addressCheckInstitutionId was null or undefined when calling deleteLotteryOfAddressCheckInstitution.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('token', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/public/v1/institution/{addressCheckInstitutionId}/lottery`.replace(
          `{${'addressCheckInstitutionId'}}`,
          encodeURIComponent(
            String(requestParameters.addressCheckInstitutionId)
          )
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async deleteLotteryOfAddressCheckInstitution(
    requestParameters: DeleteLotteryOfAddressCheckInstitutionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.deleteLotteryOfAddressCheckInstitutionRaw(
      requestParameters,
      initOverrides
    );
  }

  /**
   */
  async getAddressCheckConfigurationRaw(
    requestParameters: GetAddressCheckConfigurationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AddressCheckConfigurationDto>> {
    if (
      requestParameters.addressCheckInstitutionId === null ||
      requestParameters.addressCheckInstitutionId === undefined
    ) {
      throw new runtime.RequiredError(
        'addressCheckInstitutionId',
        'Required parameter requestParameters.addressCheckInstitutionId was null or undefined when calling getAddressCheckConfiguration.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('token', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/public/v1/institution/{addressCheckInstitutionId}/configuration`.replace(
          `{${'addressCheckInstitutionId'}}`,
          encodeURIComponent(
            String(requestParameters.addressCheckInstitutionId)
          )
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AddressCheckConfigurationDtoFromJSON(jsonValue)
    );
  }

  /**
   */
  async getAddressCheckConfiguration(
    requestParameters: GetAddressCheckConfigurationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AddressCheckConfigurationDto> {
    const response = await this.getAddressCheckConfigurationRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   */
  async getLotteryOfAddressCheckInstitutionRaw(
    requestParameters: GetLotteryOfAddressCheckInstitutionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<LotteryDto>> {
    if (
      requestParameters.addressCheckInstitutionId === null ||
      requestParameters.addressCheckInstitutionId === undefined
    ) {
      throw new runtime.RequiredError(
        'addressCheckInstitutionId',
        'Required parameter requestParameters.addressCheckInstitutionId was null or undefined when calling getLotteryOfAddressCheckInstitution.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('token', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/public/v1/institution/{addressCheckInstitutionId}/lottery`.replace(
          `{${'addressCheckInstitutionId'}}`,
          encodeURIComponent(
            String(requestParameters.addressCheckInstitutionId)
          )
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LotteryDtoFromJSON(jsonValue)
    );
  }

  /**
   */
  async getLotteryOfAddressCheckInstitution(
    requestParameters: GetLotteryOfAddressCheckInstitutionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<LotteryDto> {
    const response = await this.getLotteryOfAddressCheckInstitutionRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   */
  async getSchoolDataRaw(
    requestParameters: GetSchoolDataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SchoolDataDto>> {
    if (
      requestParameters.addressCheckInstitutionId === null ||
      requestParameters.addressCheckInstitutionId === undefined
    ) {
      throw new runtime.RequiredError(
        'addressCheckInstitutionId',
        'Required parameter requestParameters.addressCheckInstitutionId was null or undefined when calling getSchoolData.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('token', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/public/v1/institution/{addressCheckInstitutionId}/schooldata`.replace(
          `{${'addressCheckInstitutionId'}}`,
          encodeURIComponent(
            String(requestParameters.addressCheckInstitutionId)
          )
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SchoolDataDtoFromJSON(jsonValue)
    );
  }

  /**
   */
  async getSchoolData(
    requestParameters: GetSchoolDataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SchoolDataDto> {
    const response = await this.getSchoolDataRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   */
  async getStepOfAddressCheckInstitutionRaw(
    requestParameters: GetStepOfAddressCheckInstitutionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AddressCheckInstitutionStepDto>> {
    if (
      requestParameters.addressCheckInstitutionId === null ||
      requestParameters.addressCheckInstitutionId === undefined
    ) {
      throw new runtime.RequiredError(
        'addressCheckInstitutionId',
        'Required parameter requestParameters.addressCheckInstitutionId was null or undefined when calling getStepOfAddressCheckInstitution.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('token', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/public/v1/institution/{addressCheckInstitutionId}/step`.replace(
          `{${'addressCheckInstitutionId'}}`,
          encodeURIComponent(
            String(requestParameters.addressCheckInstitutionId)
          )
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AddressCheckInstitutionStepDtoFromJSON(jsonValue)
    );
  }

  /**
   */
  async getStepOfAddressCheckInstitution(
    requestParameters: GetStepOfAddressCheckInstitutionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AddressCheckInstitutionStepDto> {
    const response = await this.getStepOfAddressCheckInstitutionRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   */
  async getTeacherStaffOfAddressCheckInstitutionRaw(
    requestParameters: GetTeacherStaffOfAddressCheckInstitutionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TeacherStaffDto>> {
    if (
      requestParameters.addressCheckInstitutionId === null ||
      requestParameters.addressCheckInstitutionId === undefined
    ) {
      throw new runtime.RequiredError(
        'addressCheckInstitutionId',
        'Required parameter requestParameters.addressCheckInstitutionId was null or undefined when calling getTeacherStaffOfAddressCheckInstitution.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('token', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/public/v1/institution/{addressCheckInstitutionId}/teacherstaff`.replace(
          `{${'addressCheckInstitutionId'}}`,
          encodeURIComponent(
            String(requestParameters.addressCheckInstitutionId)
          )
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TeacherStaffDtoFromJSON(jsonValue)
    );
  }

  /**
   */
  async getTeacherStaffOfAddressCheckInstitution(
    requestParameters: GetTeacherStaffOfAddressCheckInstitutionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TeacherStaffDto> {
    const response = await this.getTeacherStaffOfAddressCheckInstitutionRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   */
  async sendAddressCheckFeedbackRaw(
    requestParameters: SendAddressCheckFeedbackRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AddressCheckFeedbackDto>> {
    if (
      requestParameters.addressCheckInstitutionId === null ||
      requestParameters.addressCheckInstitutionId === undefined
    ) {
      throw new runtime.RequiredError(
        'addressCheckInstitutionId',
        'Required parameter requestParameters.addressCheckInstitutionId was null or undefined when calling sendAddressCheckFeedback.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('token', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/public/v1/institution/{addressCheckInstitutionId}/feedback`.replace(
          `{${'addressCheckInstitutionId'}}`,
          encodeURIComponent(
            String(requestParameters.addressCheckInstitutionId)
          )
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AddressCheckFeedbackDtoToJSON(
          requestParameters.addressCheckFeedbackDto
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AddressCheckFeedbackDtoFromJSON(jsonValue)
    );
  }

  /**
   */
  async sendAddressCheckFeedback(
    requestParameters: SendAddressCheckFeedbackRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AddressCheckFeedbackDto> {
    const response = await this.sendAddressCheckFeedbackRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   */
  async updateSchoolDataRaw(
    requestParameters: UpdateSchoolDataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SchoolDataDto>> {
    if (
      requestParameters.addressCheckInstitutionId === null ||
      requestParameters.addressCheckInstitutionId === undefined
    ) {
      throw new runtime.RequiredError(
        'addressCheckInstitutionId',
        'Required parameter requestParameters.addressCheckInstitutionId was null or undefined when calling updateSchoolData.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('token', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/public/v1/institution/{addressCheckInstitutionId}/schooldata`.replace(
          `{${'addressCheckInstitutionId'}}`,
          encodeURIComponent(
            String(requestParameters.addressCheckInstitutionId)
          )
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: SchoolDataDtoToJSON(requestParameters.schoolDataDto),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SchoolDataDtoFromJSON(jsonValue)
    );
  }

  /**
   */
  async updateSchoolData(
    requestParameters: UpdateSchoolDataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SchoolDataDto> {
    const response = await this.updateSchoolDataRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   */
  async updateStepOfAddressCheckInstitutionRaw(
    requestParameters: UpdateStepOfAddressCheckInstitutionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AddressCheckInstitutionStepDto>> {
    if (
      requestParameters.addressCheckInstitutionId === null ||
      requestParameters.addressCheckInstitutionId === undefined
    ) {
      throw new runtime.RequiredError(
        'addressCheckInstitutionId',
        'Required parameter requestParameters.addressCheckInstitutionId was null or undefined when calling updateStepOfAddressCheckInstitution.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('token', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/public/v1/institution/{addressCheckInstitutionId}/step`.replace(
          `{${'addressCheckInstitutionId'}}`,
          encodeURIComponent(
            String(requestParameters.addressCheckInstitutionId)
          )
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.body as any,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AddressCheckInstitutionStepDtoFromJSON(jsonValue)
    );
  }

  /**
   */
  async updateStepOfAddressCheckInstitution(
    requestParameters: UpdateStepOfAddressCheckInstitutionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AddressCheckInstitutionStepDto> {
    const response = await this.updateStepOfAddressCheckInstitutionRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   */
  async updateTeacherStaffOfAddressCheckInstitutionRaw(
    requestParameters: UpdateTeacherStaffOfAddressCheckInstitutionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TeacherStaffDto>> {
    if (
      requestParameters.addressCheckInstitutionId === null ||
      requestParameters.addressCheckInstitutionId === undefined
    ) {
      throw new runtime.RequiredError(
        'addressCheckInstitutionId',
        'Required parameter requestParameters.addressCheckInstitutionId was null or undefined when calling updateTeacherStaffOfAddressCheckInstitution.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('token', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/public/v1/institution/{addressCheckInstitutionId}/teacherstaff`.replace(
          `{${'addressCheckInstitutionId'}}`,
          encodeURIComponent(
            String(requestParameters.addressCheckInstitutionId)
          )
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: TeacherStaffDtoToJSON(requestParameters.teacherStaffDto),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TeacherStaffDtoFromJSON(jsonValue)
    );
  }

  /**
   */
  async updateTeacherStaffOfAddressCheckInstitution(
    requestParameters: UpdateTeacherStaffOfAddressCheckInstitutionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TeacherStaffDto> {
    const response = await this.updateTeacherStaffOfAddressCheckInstitutionRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
